@font-face {
    font-family: 'Co Headline';
    font-style: normal;
    font-weight: 400;
    src: local('Co Headline Regular'), url('./assets/fonts/Co Headline.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Co Headline';
    font-style: normal;
    font-weight: 300;
    src: local('Co Headline Light'), url('./assets/fonts/Co Headline Light.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Co Headline';
    font-style: normal;
    font-weight: 700;
    src: local('Co Headline Bold'), url('./assets/fonts/Co Headline Bold.woff') format('woff');
  }
  